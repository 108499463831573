<template>
	<div class="feedback">
		<van-nav-bar
			title="留言反饋"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="form qjc-texta-l">
			<h5 class="title qjc-fts-32 qjc-c-dark qjc-ftw-b">反饋類型</h5>
			<van-radio-group v-model="type" icon-size="0.32rem">
			  <van-cell-group>
			    <van-cell title="投訴" clickable @click="type = '1'">
			      <van-radio slot="right-icon" name="1" />
			    </van-cell>
			    <van-cell title="意見建議" clickable @click="type = '2'">
			      <van-radio slot="right-icon" name="2" />
			    </van-cell>
			    <van-cell title="其他" clickable @click="type = '3'">
			      <van-radio slot="right-icon" name="3" />
			    </van-cell>
			  </van-cell-group>
			</van-radio-group>
			
			<van-field
				v-model="describe"
				type="textarea"
				placeholder="簡單描述情況,我們會盡快幫您解決問題"
				autosize
				class="describe qjc-fts-32"
			/>
			
			<van-field
				v-model="phone"
				placeholder="請留下手機號以便為您服務"
				class="phone qjc-fts-32"
				type="digit"
			/>
			
			<van-button @click="submit" class="submit qjc-wid-100 qjc-ftw-b qjc-bg-primary qjc-c-fff">提交</van-button>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar, RadioGroup, Radio, CellGroup, Cell, Field, Button, Toast } from "vant"
	Vue.use(NavBar)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(CellGroup)
	   .use(Cell)
	   .use(Field)
	   .use(Button)
	   .use(Toast);
	   
	import { isPhone, isHKPhone } from "@/js/utils"
	import { feedback } from "@/js/axios"
	
	export default{
		name: "userFeedback",
		data (){
			return {
				type: '',//反饋類型
				describe: '',//描述
				phone: ''//手機號
			}
		},
		methods: {
			submit (){
				if(this.type == ''){
					Toast.fail('請選擇反饋類型');
				}else if(this.describe.trim() == ''){
					Toast.fail('請簡單描述您所遇到的情況');
				}else if(this.phone == '' || !(isPhone(this.phone) || isHKPhone(this.phone))){
					Toast.fail('手機號格式不正確');
				}else{
					feedback({
						type: this.type,
						phone: this.phone,
						content: this.describe
					}).then(res => {
						if(res.status == 1){
							Toast.success(res.msg);
						}else{
							Toast.fail(res.msg);
						}
					});
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.feedback{
		padding: 0.88rem 0.24rem;
		
		.title{
			margin-top: 0.48rem;
		}
		.van-hairline--top-bottom::after{
			border: none;
		}
		.van-cell:not(:last-child)::after{
			left: 0;
			border-bottom: 1px solid#DCDEE5;
			transform: none;
		}
		.van-cell:last-child{
			border-bottom: 1px solid#DCDEE5;
			transform: none;
		}
		.van-radio-group{
			.van-cell{
				padding: 0.4rem 0.18rem 0.4rem 0;
				margin-left: 0.18rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #40444D;
			}
		}
		.describe::after,
		.phone::after{
			border-bottom: none !important;
		}
		.describe{
			width: 100%;
			min-height: 2rem;
			padding: 0.32rem;
			border: 1px solid #DCDEE5;
			margin: 0.56rem 0 0;
			border-radius: 0.08rem;
			line-height: 0.48rem;
		}
		.phone{
			margin-top: 0.32rem;
			margin-bottom: 0.88rem;
			height: 0.94rem;
			line-height: 0.94rem;
			border: 1px solid #DCDEE5;
			border-radius: 0.08rem;
			padding: 0 0.32rem;
		}
		.submit{
			font-size: 0.34rem;
			border-radius: 0.08rem;
			height: 0.88rem;
			line-height: 0.88rem;
		}
		
		::-webkit-input-placeholder { /* WebKit browsers */
		    font-weight: 400;
		}
		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		    font-weight: 400;
		}
		::-moz-placeholder { /* Mozilla Firefox 19+ */
		    font-weight: 400;
		}
		:-ms-input-placeholder { /* Internet Explorer 10+ */
		    font-weight: 400;
		}
	}
</style>
